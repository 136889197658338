<template>
    <v-card class="mx-auto" width="800">
        <v-card-text>
            <psi-form-element label="Select Session" required>
                <psi-form-select-field
                    :items="sessions"
                    item-text="name"
                    item-value="id"
                    label="Select Session"
                    name="session"
                    :value="session"
                    id="select-session"
                    @input="onInput"
                    return-object
                ></psi-form-select-field>
            </psi-form-element>
            <instructor-list
                :instructors="instructors"
                @select="$emit('instructor-select', $event)"
            ></instructor-list>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "instructor-select",
    components: {
        InstructorList: () => import("../components/InstructorList"),
    },
    props: {
        sessions: {
            type: Array,
            required: true,
        },
        selectedSession: {
            required: false,
        },
        program: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            session: 0,
            instructors: [],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        onInput(item) {
            this.instructors = item.instructors.filter((instructor) => {
                return instructor.programs.some(
                    (program) => program.id === this.program.program.id
                );
            });
            this.$emit("update:selectedSession", item);
        },
    },
};
</script>

<style scoped>
</style>