var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { width: "800" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "psi-form-element",
            { attrs: { label: "Select Session", required: "" } },
            [
              _c("psi-form-select-field", {
                attrs: {
                  items: _vm.sessions,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Select Session",
                  name: "session",
                  value: _vm.session,
                  id: "select-session",
                  "return-object": ""
                },
                on: { input: _vm.onInput }
              })
            ],
            1
          ),
          _c("instructor-list", {
            attrs: { instructors: _vm.instructors },
            on: {
              select: function($event) {
                return _vm.$emit("instructor-select", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }